import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "I want to add my cameras live video to the ioBroker Visualization",
  "path": "/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/",
  "dateChanged": "2020-05-04",
  "author": "Mike Polinowski",
  "excerpt": "I want to add my cameras live video to the ioBroker Visualization. But adding the Snapshot or MJPEG Stream URL to an Image or iFrame Element doesn't seem to be very stable. The stream often stops and cannot be restarted.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - I want to add my cameras live video to the ioBroker Visualization' dateChanged='2020-05-04' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to add my cameras live video to the ioBroker Visualization. But adding the Snapshot or MJPEG Stream URL to an Image or iFrame Element doesn`t seem to be very stable. The stream often stops and cannot be restarted.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/' locationFR='/fr/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Q`}</strong>{`: I want to add my cameras live video to the ioBroker Visualization. But adding the Snapshot or MJPEG Stream URL to an Image or iFrame Element doesn't seem to be very stable. The stream often stops and cannot be restarted. I saw your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/Visualisation/#display-the-camera-live-stream"
      }}>{`guide to use MotionEye`}</a>{` to solve this problem. But that is too complicated for me. Is there a better solution?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Displaying a stream of single snapshots inside your browser can quickly lead to cache problems. To mediate this issue we recommend to display a preview of your cameras video with a low refresh rate (e.g. 1 snapshot every 5 seconds). By clicking on that preview we want to be able to open a floating window that displays the full resolution stream of our camera with the max. refresh rate of 1fps that the iobroker Vis supports.`}</p>
    <p>{`Start by installing the `}<strong parentName="p">{`jQuery-UI-Stil Widget`}</strong>{` Adapter from the adapter menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c580ab534103509a1f486b03de09f2d3/2bef9/INSTAR_ioBroker_Vis_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeUlEQVQoz42Nyy7EUACG+xqewMJebDyDjcSGBRthNYsZ9qwIW4kNG7ERJphkBJW4C4bpoCPTMzptZ9rTHj2Xnp7WJU0lbivCl2/5f/mlrt7RFfkyM7PUN381vv+a2Ra5bTYng/WSlb+w8tdOQWllZdFfeB7aFIObYqggRorRcPFpZK0ltXf3TC3kBycWc8ul7A7PFv3clr9Rdm8aqATc86Ny5Uw5O7o9uKgfavjDY4CPAdlXHamto6t7YKyzNzO7ejJ9Gk/uPZYMKjiLOA0oIdBhEAbQCR/dKCDfxpwyjKSKUgb1OmyZyHPvgf4Sh8lzRHlk+xxjjCn7kmJMviWEuh6SNu7YYTORrfTeZg3TatougnYZ2FuKzdn77CcopR5CUuHW361xWX/VHKLeVBSlogGtoeuWaZLf+Yx9hF5ikaYpY/RB1w3DsCzLNM1ms/V3jINQxE9pmlJKq9Wqqqo1rQYAMIx/PAehIIwnSRKGIYTQfcfzPITQn/EbDw/P5Z7Wg1IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c580ab534103509a1f486b03de09f2d3/e4706/INSTAR_ioBroker_Vis_01.avif 230w", "/en/static/c580ab534103509a1f486b03de09f2d3/d1af7/INSTAR_ioBroker_Vis_01.avif 460w", "/en/static/c580ab534103509a1f486b03de09f2d3/7f308/INSTAR_ioBroker_Vis_01.avif 920w", "/en/static/c580ab534103509a1f486b03de09f2d3/e3723/INSTAR_ioBroker_Vis_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c580ab534103509a1f486b03de09f2d3/a0b58/INSTAR_ioBroker_Vis_01.webp 230w", "/en/static/c580ab534103509a1f486b03de09f2d3/bc10c/INSTAR_ioBroker_Vis_01.webp 460w", "/en/static/c580ab534103509a1f486b03de09f2d3/966d8/INSTAR_ioBroker_Vis_01.webp 920w", "/en/static/c580ab534103509a1f486b03de09f2d3/a9a89/INSTAR_ioBroker_Vis_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c580ab534103509a1f486b03de09f2d3/81c8e/INSTAR_ioBroker_Vis_01.png 230w", "/en/static/c580ab534103509a1f486b03de09f2d3/08a84/INSTAR_ioBroker_Vis_01.png 460w", "/en/static/c580ab534103509a1f486b03de09f2d3/c0255/INSTAR_ioBroker_Vis_01.png 920w", "/en/static/c580ab534103509a1f486b03de09f2d3/2bef9/INSTAR_ioBroker_Vis_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c580ab534103509a1f486b03de09f2d3/c0255/INSTAR_ioBroker_Vis_01.png",
              "alt": "Reset your INSTAR IP Camera",
              "title": "Reset your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now open the Vis configuration window and add a `}<strong parentName="p">{`Cam/Snapshot Dialog`}</strong>{` widget:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8fa8a6e4d23aaf16319e520739e02bde/1ac29/INSTAR_ioBroker_Vis_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC6UlEQVQoz32RWWsTURiG5x8JIi54pRe1aKtWrbulglaEVgW9UbwTRC8UrYoKSgpdFKN2MW3VVqVpWk3SdNKYPTOTpZNMJufMyZzZMpPZJIogiD583/X7vLzE5+WVSCIby+ajaZpMUavJ3GoyRyapSCIbTTORRC6SyK0m6Xiu8OXryqel0IeF5flAkExR84FvRIZickyhsF7mgVDHEkSiUMc8ECo8tF3Xdh3XdbHS4IBQqdaKbFkQcYmtMMX1YDhCVMpljuNEhAKLix6PZ+zF2Nvx15O+ibfj3ql34z7f5MTEm6UlP0PTNEVVuQrLslgUzaaRzWYJRVawKNquO/rKe7K398z5c0eOdXd2tB09cejwsa7uo137ujsfPB7MpNOFQgFCyPM8AEAQhFgsRqSSSR4hm1ybfvL0nsfjnfHdvHVr58bNe9t2Hty968LlgbP9Z4ZGh0ANSJKEcetFjBVZTqSSBEPTmmW50TXvnfvXBx+8fD9799HTPTvaD+3Z3b5l68Ur/X0DfSMvRwQIEZZF3W7ardNMO0MzBGwhOK4biDODrz8Oeqeu3rjd0bbv8IED7du2918633fh3PDYsIAQhlBIprhVko9Gi+E1MhonGIZRVc0ym99zXLGm+VPstYdjnV29nR37t2/YdKq35/jpnmfPnymqJgGghVaa4YgRXrFJshoKEq0mkmSZZgUgioX5aj0cp+a/BObmPs9OTS/4/f7AYpQkkSjKANQWFnEwLAVD/HKEGvUSmXQGQmiapm3b7l84TmtnSZI4AJoIgYkp5Jup+2aA73354eOWtq7r1k+c39h/4DgOxhKEsNm0RSRbmmaqqqPrpUyWqNeRgIR/Jf9CVVWe53XDRKKk67phGLZt54ul1lSqqlqW9R9tWZI4nm9AQQwsmbHv5lrMiSfKc/MExljTtP93lmV5nWVlhBQm3yitN4olgy3ToRCRZ5harfbL5F/aiqJUq5xuGFUA5EZD0jTVMKKJ+A8TI+9+sGtBUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fa8a6e4d23aaf16319e520739e02bde/e4706/INSTAR_ioBroker_Vis_02.avif 230w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/d1af7/INSTAR_ioBroker_Vis_02.avif 460w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/7f308/INSTAR_ioBroker_Vis_02.avif 920w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/5dd4b/INSTAR_ioBroker_Vis_02.avif 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8fa8a6e4d23aaf16319e520739e02bde/a0b58/INSTAR_ioBroker_Vis_02.webp 230w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/bc10c/INSTAR_ioBroker_Vis_02.webp 460w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/966d8/INSTAR_ioBroker_Vis_02.webp 920w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/6c19f/INSTAR_ioBroker_Vis_02.webp 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8fa8a6e4d23aaf16319e520739e02bde/81c8e/INSTAR_ioBroker_Vis_02.png 230w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/08a84/INSTAR_ioBroker_Vis_02.png 460w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/c0255/INSTAR_ioBroker_Vis_02.png 920w", "/en/static/8fa8a6e4d23aaf16319e520739e02bde/1ac29/INSTAR_ioBroker_Vis_02.png 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8fa8a6e4d23aaf16319e520739e02bde/c0255/INSTAR_ioBroker_Vis_02.png",
              "alt": "Reset your INSTAR IP Camera",
              "title": "Reset your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For a camera with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{`, the HTTP Port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`80`}</code>{` and the user login `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/instar`}</code>{` (change thos according to your camera setup) add the following URL in the `}<strong parentName="p">{`group_icon`}</strong>{` / `}<strong parentName="p">{`icon`}</strong>{` field and give it an `}<strong parentName="p">{`Update Interval`}</strong>{` in milliseconds, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5000`}</code>{` to update the image every five seconds:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.117:80/tmpfs/auto.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p>{`Every camera has `}<Link to="/Outdoor_Cameras/IN-9020_HD/Video_Streaming/" mdxType="Link">{`3 snapshots with different resolutions`}</Link>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`auto.jpg`}</code>{` is the snapshot with a medium resolution.`}</p>
    <p>{`For the `}<strong parentName="p">{`Dialog URL`}</strong>{` we want to use the highest resolution instead - with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`snap.jpg`}</code>{` - and an `}<strong parentName="p">{`Update Interval`}</strong>{` of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1000 ms`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.117:80/tmpfs/snap.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "/en/52bea72d48429b012240b12dfeca893b/INSTAR_ioBroker_Vis_03.gif",
        "alt": "Reset your INSTAR IP Camera"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      